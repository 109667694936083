
html, body{
	background: rgb(10,10,10);
	color:rgb(180,180,180);
	width: 100%;
	min-width: 320px;
	height: 100%;
	margin: 0 auto;
	padding: 0;
	scroll-behavior: smooth;
	&.noscroll {
		overflow: hidden;
	}
}

section {
	position: relative;
	margin: 0 0 20px 0;
	padding-top: 10px;
	h2 {
		margin-bottom: 10px;
	}
}

header {
	align-items: flex-end;
	background: rgb(10,10,10);
	border-bottom: 1px solid rgb(50, 50, 50);
	display: flex;
	justify-content: space-between;
	padding: 24px;
	position: fixed;
	top:0;
	left: 0; 
	right: 0;
	z-index: 9;
	@include xsmall {
		flex-direction: column;
		padding: 20px 20px 0 20px
	}
	.logo-container {
		display: block;
		.logo-richardtirta {
			@include transition-quad;
			display: block;
			width: 500px;
			overflow: hidden;
			@include xsmall {
				width: 100%;
				margin: 0 auto;
			}
			@include large {
				width: 500px;
			}
			img {
				display: block;
				width: 100%;
			}
			span {
				position: absolute;
				top: 0;
				left: -999px;
			}
		}
	}
	nav {
		display: flex;
		justify-content: space-around;
		@include xsmall {
			justify-content: flex-end;
			margin-top: 14px;
		}
		a {
			padding: 10px 10px 0 10px;
			margin-right: 4px;
			margin-left: 4px;
			@include xsmall {
				padding-bottom: 10px;
			}
		}
	}
	
}