//========= TYPOGRAPHY ==========//

$base: 16; // Default

@function em($pixels, $context: $base) {
  @return #{$pixels/$context}em
}

@font-face {
    font-family: 'UniversLTStd67BoldCondensed';
    src: url('../fonts/universltstd-boldcn-webfont.eot');
    src: url('../fonts/universltstd-boldcn-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/universltstd-boldcn-webfont.woff') format('woff'),
         url('../fonts/universltstd-boldcn-webfont.ttf') format('truetype'),
         url('../fonts/universltstd-boldcn-webfont.svg#UniversLTStd67BoldCondensed') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'UniversLTStd57Condensed';
    src: url('../fonts/universltstd-cn-webfont.eot');
    src: url('../fonts/universltstd-cn-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/universltstd-cn-webfont.woff') format('woff'),
         url('../fonts/universltstd-cn-webfont.ttf') format('truetype'),
         url('../fonts/universltstd-cn-webfont.svg#UniversLTStd57Condensed') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'FuturaStdBoldCondensed';
    src: url('../fonts/futurastd-condensedbold-webfont.eot');
    src: url('../fonts/futurastd-condensedbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futurastd-condensedbold-webfont.woff') format('woff'),
         url('../fonts/futurastd-condensedbold-webfont.ttf') format('truetype'),
         url('../fonts/futurastd-condensedbold-webfont.svg#FuturaStdBoldCondensed') format('svg');
    font-weight: normal;
    font-style: normal;

}


html{
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-ms-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;
}



body {
	font-family:'UniversLTStd57Condensed',Helvetica, sans-serif;
	font-size: 18px;
	font-weight: normal;
	letter-spacing: normal;
}

h1, h2, h3, h4, h5 {
	margin: 0;
}

h1 {
	color: #1B75BB;
	font-family:'FuturaStdBoldCondensed',Helvetica, sans-serif;
	font-size: em(20);
	letter-spacing: 0.02em;
	font-weight: normal;
	line-height: 1.2;
	@include medium {
		font-size: em(24);
	}
}


h2 {
	font-size: em(20);
	letter-spacing: 0.08em;
	line-height: 1.2;
	@include medium {
		font-size: em(22);
	}
}

h3 {
	font-size: em(19);
	font-weight: 400;
	letter-spacing: 0.01em;
	margin-bottom: 14px;
}

h4 {
	font-size: em(22);
	font-weight: normal;
	line-height: 1.1;
	margin: 5px 0;
}

h5 {
	font-size: em(30);
	font-weight: normal;
	line-height: 0.9;
	letter-spacing: normal;
}

h6 {
	font-size: em(24);
	font-weight: normal;
	line-height: 1;
	margin: 0;
	letter-spacing: normal;
}

p {
	letter-spacing: 0.02em;
	line-height: 1.4;
	margin-top: 20px;
	strong {
		font-weight: bold;
	}
}

a {
	color:rgb(180,180,180);
	font-weight: 600;
	// font-size: em(18);
	text-decoration: none;
	&:hover {
		color:rgb(200,200,200);
		text-decoration: underline;
	}
}

.white {
	color: white;
}

.align-right {
	text-align: right;
}

a[href^="tel"], a[href^="mailto:"]{
   	font-size: inherit;
}

input {
	font-size: em(15);
	letter-spacing: normal;
}

small {
	font-size: em(12);
	line-height: 11px;
}
