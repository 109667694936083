

@mixin xlarge {
	@media (min-width: 1200px) { @content; }
}

@mixin large {
	@media (min-width: 1000px) { @content; }
}

@mixin medium {
	@media (min-width: 700px) { @content; }
}

//default is small

@mixin xsmall {
	@media (max-width: 600px) { @content; }
}

@mixin short {
	@media (max-height: 500px) { @content; }
}

@mixin xshort {
	@media (max-height: 400px) { @content; }
}


$dark-gray: #333;
$blue: #1B75BB;

@mixin clearfix {
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}

@mixin margin-center {
	margin-left: auto;
	margin-right: auto;
}

@mixin absolute-center {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

@mixin transition-cubic {
	transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

@mixin transition-quad {
	transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@mixin hide-show {
	transition: opacity 500ms ease, visibility 500ms ease;
	opacity: 1;
	visibility: visible;
	&.hidden {
		opacity: 0;
		visibility: hidden;
		z-index: -10;
	}
}

