
.intro-text {
	width: 100%;
	max-width: 450px;
}

.main {
	margin-top: 120px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1300px;
	padding-left: 30px;
	padding-right: 30px;
	width: 100%;
	@include xsmall {
		padding-left: 20px;
		padding-right: 20px;
		width: calc(100% - 40px);
		margin-left: 0;
		margin-right: 0;
		&.lightbox-height {
			height: 100vh;
			overflow-y: hidden;
		}
	}
	h3 {
		align-items: center;
		display: flex;
		.featured {
			margin-right: 4px;
		}
	}
}

.project-container {
	background: #333;
	border-radius: 8px;
	display: flex;
	margin-top: 20px;
	text-decoration: none;
	&:hover {
		background: rgb(75,75,75);
		transform: scale(1.02);
		text-decoration: none;
		transition: all 0.15s ease-in;
	}
	@include xsmall {
		flex-direction: column;
		margin-top: 40px;
		width: 100%;
	}
	.project-description {
		color: #ccc;
		padding: 16px;
		text-align: left;
		width: 35%;
		@include xsmall {
			width: calc(100% - 32px);
		}
	}
	.project-tools {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		span {
			border: 1px solid #ccc;
			margin-right: 8px;
			margin-top: 8px;
			line-height: 1.2;
			padding: 4px;
		}
	}
	.project-image {
		width: 65%;
		height: 200px;
		overflow: hidden;
		@include xsmall {
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
			width: 100%;
		}
		img {
			width: 100%;
			@include xsmall {
				height: 100%;
				transform: translateX(-25%);
				width: auto;
			}
		}
	}
}


.lightbox-container {
	background: #000;
	display: block;
	bottom:0;
	height: 100vh;
	top:0;
	left:0;
	right: 0;
	margin-top: 20px;
	padding: 50px;
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	z-index: 20;
	@include xsmall {
		padding: 20px;
		width: calc(100% - 40px);
	}
	.lightbox-close {
		margin-top: 10px;
	}
	.lightbox-project{
		display: flex;
		margin-top: 50px;
		@include xsmall {
			flex-direction: column;
		}
	}
	.lightbox-description {
		color: #ccc;
		padding-right: 30px;
		text-align: left;
		width: 30%;
		@include xsmall {
			width: 100%;
		}
	}
	.lightbox-tools {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		span {
			border: 1px solid #ccc;
			margin-right: 8px;
			margin-top: 8px;
			line-height: 1.2;
			padding: 4px;
		}
	}
	.lightbox-image {
		width: 70%;
		overflow: hidden;
		padding-right: 100px;
		@include xsmall {
			margin-top: 20px;
			padding-right: 0;
			width: 100%;
		}
		img {
			width: 100%;
		}
	}
	.video-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		height: auto;
	}
}

.about {
	width: 100%;
}

.about-container {
	//align-items: flex-end;
	display: flex;
	justify-content: space-between;
	@include xsmall {
		flex-direction: column;
	}
	.about-text {
		max-width: 550px;
		width: 50%;
		@include xsmall {
			width: 100%;
		}
	}
	
	
	.about-picture {
		width: 50%;
		@include xsmall {
			width: 100%;
		}
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 450px;
			width: 100%;
		}
	}
}